.css-1tfylnl-MuiToolbar-root{border-bottom: 1px solid #e1e1e1;}
.filtered{  float: right;}
.heading-h4 {
    display: inline-block;
    margin-bottom: 0;
    line-height: 0;
    padding-top: 30px;
}

.chart_box {
    background: #fff;
    padding: 30px;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0px 1px 5px #ddd; 
    margin-top: 10px;
}
.group_name{width:20.33%; }
.group_desc{width:20.33%; }
.group_type{width:20.33%; }
.group_creat{width:13%; }
.group_status{width:13%; }
.group_delete{width:13%; }
.group_delete span{background-color: unset;}
.group_eyes{width:13%; }
.groop-management-table thead tr th{width:10%; }
.groop-management-table thead tr th:nth-child(2){text-align: center;padding-left: 26px;}
.groop-management-table .MuiTableCell-body{word-break: break-all; padding: 16px;}
.groop-management-table .css-1bexa4u-MuiTypography-root{white-space: inherit;}
.d-none, .groop-management-table thead tr th:first-child, .groop-management-table thead tr td:last-child{display: none;}
.css-1byr0tz {background: #edeff2;}
.blues_icon{filter: invert(1);}
.groups_icon{filter: hue-rotate(40deg);}


.user-mgmt thead tr th{width:18%;}
/* .user-mgmt thead tr th{text-align:center} */
.user-mgmt thead tr th:nth-child(2){padding-left:26px; width: 10% !important;}
.user-mgmt tbody tr td:nth-child(2){padding-left:26px; width: 10% !important;}
.user-mgmt .MuiTableCell-body{word-break: break-all; padding: 16px;}
.d-none, .user-mgmt thead tr th:first-child, .user-mgmt thead tr td:last-child, .user-mgmt thead tr th:last-child, .user-mgmt thead tr td:last-child{display: none;}
.user-mgmt  .css-1bexa4u-MuiTypography-root{white-space: inherit;}
.user-mgmt .MuiTableCell-body:last-child{display: none;}

/* .sub-mgmt thead tr th{width:18%; } */
/* .user-mgmt thead tr th{text-align:center} */
.sub-mgmt thead tr th:nth-child(2){padding-left:52px;}
.sub-mgmt tbody tr td:nth-child(2){padding-left:62px}
.sub-mgmt .MuiTableCell-body{word-break: break-all; padding: 16px;}
.d-none, .sub-mgmt thead tr th:first-child, .sub-mgmt thead tr td:last-child, .sub-mgmt thead tr th:last-child, .sub-mgmt thead tr td:last-child{display: none;}
.sub-mgmt  .css-1bexa4u-MuiTypography-root{white-space: inherit;}
.sub-mgmt .MuiTableCell-body:last-child{display: none;}
.sub-mgmt thead tr th{padding-right: 52px;}
@media (min-width: 1200px)
{
.css-1oqqzyl-MuiContainer-root {
    max-width: 100%;
}
}